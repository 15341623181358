<template>
    <div class="body">
        <!-- banner区域 Start -->
        <div class="banner">
            <m-top-bar></m-top-bar>
        </div>
        <!-- banner区域 End -->

        <!-- switchBar区域 Start -->
        <div class="switchBar">
            <div class="switchBox">云智能维护管理平台</div>
        </div>
        <div class="line"></div>
        <!-- switchBar区域 End -->

        <!-- container区域 Start -->
        <div class="container">
            <div class="containerBox">
                <img src="../../../../assets/mobile/img/product/managementPlatform.png" alt="" class="pic">
                <div class="title">云智能维护管理平台</div>
                <div class="text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;宁讯网络管理平台是企业级的网络管理系统，采用单一的软件解决方案，为网络管理员简化了大量设备管理和监控任务。多种网络都可使用一个服务器实现监控和维护。丰富的设置特性帮助您有效诊断网络问题，可视化网络地图，自动按计划执行固件升级，更新设备状态，获取失败的
                    警报信息，收集统计数据等。基于 Web 系统环境，支持多用户账号管理。多个管理员可以在同一个服务器上管理不同的网络，且不用直接访问各自网络的设备。
                </div>
            </div>
        </div>
        <!-- container区域 End -->

        <!-- baseBar区域 Start -->
        <m-base-bar></m-base-bar>
        <!-- baseBar区域 End -->
    </div>
</template>
    
<script>
import mTopBar from '@/components/mTopBar.vue'
import mBaseBar from '@/components/mBaseBar.vue'

export default {
    name: 'Support',
    components: {
        mTopBar,
        mBaseBar,
    }
}
</script>
  
<style scoped>
.body {
    background-color: #f7f7f7 !important;
}

/* banner区域 */
.banner {
    width: 100%;
    min-height: 2050px;
    background: url(../../../../assets/mobile/img/solution/banner.jpg) center no-repeat;
    background-size: 335%;
}

/* switchBar区域 */
.switchBar {
    padding-top: 50px;
    width: 100%;
    height: 300px;
    text-align: center;
    background-color: #ffffff;
}

.switchBar .switchBox {
    margin: 0 auto;
    text-align: center;
    width: 800px;
    height: 200px;
    font-size: 72px;
    line-height: 200px;
    color: #ffffff;
    border-radius: 30px;
    background-color: #c3a35d;
}

.line {
    margin: 0 auto;
    width: 90%;
    border-bottom: 1px solid #c3a35d;
}

/* container区域 */
.container {
    padding: 50px;
    width: 100%;
    min-height: 500px;
}

.container .containerBox {
    padding: 100px;
    width: 100%;
    min-height: 300px;
    text-align: justify;
    background-color: #ffffff;
}

.container .containerBox .pic {
    margin-left: 70px;
    margin-bottom: 150px;
    width: 1500px;
}

.container .containerBox .title {
    margin-bottom: 50px;
    font-weight: 700;
    font-size: 80px;
}

.container .containerBox .text {
    margin-bottom: 50px;
    font-size: 70px;
    color: #999;
}
</style>